import { ref } from 'vue'

export function useIntegrations () {
  const integrations = ref([
    {
      key: 'beetween',
      label: 'Beetween',
      description: 'Publiez automatiquement vos offres depuis Beetween vers HappyCab et son réseau de multidiffusion, et centralisez vos candidatures sur Beetween.',
      image: 'platform/jobboards/beetween.png',
      connectFn: () => {
        console.log('Beetween')
      },
    },
    {
      key: 'digitalRecruiters',
      label: 'DigitalRecruiters',
      description: 'Publiez automatiquement vos offres depuis DigitalRecruiters vers HappyCab et son réseau de multidiffusion, et centralisez vos candidatures sur DigitalRecruiters.',
      image: 'platform/jobboards/digitalrecruiters.png',
      connectFn: () => {
        console.log('DigitalRecruiters')
      },
    },
    {
      key: 'franceTravail',
      label: 'France Travail',
      description: 'Publiez automatiquement vos offres depuis France Travail vers HappyCab et son réseau de multidiffusion, et centralisez vos candidatures sur France Travail.',
      image: 'platform/jobboards/francetravail.png',
      connectFn: () => {
        console.log('franceTravail')
      },
    },
    {
      key: 'hellowork',
      label: 'Hellowork',
      description: 'Publiez automatiquement vos offres depuis Hellowork vers HappyCab et son réseau de multidiffusion, et centralisez vos candidatures sur Hellowork.',
      image: 'platform/jobboards/hellowork.png',
      connectFn: () => {
        console.log('hellowork')
      },
    },
    {
      key: 'jobAffinity',
      label: 'JobAffinity',
      description: 'Publiez automatiquement vos offres depuis JobAffinity vers HappyCab et son réseau de multidiffusion, et centralisez vos candidatures sur JobAffinity.',
      image: 'platform/jobboards/jobaffinity.png',
      connectFn: () => {
        console.log('jobAffinity')
      },
    },
    {
      key: 'laFabrique',
      label: 'LaFabrique',
      description: 'Publiez automatiquement vos offres depuis LaFabrique vers HappyCab et son réseau de multidiffusion, et centralisez vos candidatures sur LaFabrique.',
      image: 'platform/jobboards/lafabrique.png',
      connectFn: () => {
        console.log('laFabrique')
      },
    },
    {
      key: 'quarksUp',
      label: 'QuarksUp',
      description: 'Publiez automatiquement vos offres depuis QuarksUp vers HappyCab et son réseau de multidiffusion, et centralisez vos candidatures sur QuarksUp.',
      image: 'platform/jobboards/quarksup.png',
      connectFn: () => {
        console.log('quarksUp')
      },
    },
    {
      key: 'teamTailor',
      label: 'TeamTailor',
      description: 'Publiez automatiquement vos offres depuis TeamTailor vers HappyCab et son réseau de multidiffusion, et centralisez vos candidatures sur TeamTailor.',
      image: 'platform/jobboards/teamtailor.png',
      connectFn: () => {
        console.log('teamTailor')
      },
    },
    {
      key: 'weRecruit',
      label: 'WeRecruit',
      description: 'Publiez automatiquement vos offres depuis WeRecruit vers HappyCab et son réseau de multidiffusion, et centralisez vos candidatures sur WeRecruit.',
      image: 'platform/jobboards/werecruit.png',
      connectFn: () => {
        console.log('weRecruit')
      },
    }
  ])

  return {
    integrations
  }
}
