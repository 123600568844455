import { useStore } from 'vuex'
import { compact, concat, get } from 'lodash'
import { usePerms } from 'hc-core/composables/usePerms'
import { getCurrentUserId, useSession } from 'hc-core/composables/useSession'

export function useMessages () {
  // Helpers
  const store = useStore()
  const { isApplicant } = usePerms()
  const { asyncWorkAsOrg } = useSession()

  // Functions
  const isMessageSent = (msg) => {
    return isApplicant() ? msg.senderId.startsWith('usr_') && msg.senderId === getCurrentUserId() : msg.senderId.startsWith('org_')
  }

  const conversationMarkAsUnread = async (conversation) => {
    const msgIndex = conversation.messages.findIndex(m => !isMessageSent(m))
    await asyncWorkAsOrg({
      orgId: conversation.messages[msgIndex].receiverId,
      fn: async () => {
        const updatedMsg = await store.dispatch('inbox/updateMessage', {
          id: conversation.messages[msgIndex].id,
          payload: { read: false }
        })
        conversation.messages[msgIndex] = updatedMsg
        conversation.nbUnread = get(conversation, 'nbUnread', 0) + 1
      },
      store
    })
    return conversation
  }

  const conversationArchive = async (conversation) => {
    await store.dispatch('inbox/archiveConversation', {
      messageIds: get(conversation, 'messages', []).map(m => m.id),
      convIds: compact(concat(
        [get(conversation, 'conversationId', null)],
        get(conversation, 'convIds', []),
      ))
    })
  }

  return {
    conversationMarkAsUnread,
    conversationArchive
  }
}
