<template>
  <transition
    appear
    :enter-active-class="`animated ${props.in}`"
    :leave-active-class="`animated ${props.out}`"
    mode="out-in"
    :name="props.name"
  >
    <slot />
  </transition>
</template>

<script setup>
const props = defineProps({
  name: {
    type: String,
    default: 'fade'
  },
  in: {
    type: String,
    default: 'fadeIn'
  },
  out: {
    type: String,
    default: 'fadeOut'
  },
})
</script>
