<template>
  <HcSelect
    ref="AutocompleteInput"
    v-model="selectedOption"
    :label="props.label"
    placeholder="prompt.placeholder_type"
    :readonly="readonly"
    :dense="props.dense"
    :emit-value="false"
    clearable
    :autofocus="props.autofocus"
    use-input
    :options="filteredOptions"
    input-debounce="350"
    @input-value="inputHandle"
    @filter="filterFn"
    @update:model-value="selectHandle"
  />
</template>

<script setup>
//#region IMPORTS AND INIT
import { get } from 'lodash'
import { onMounted, ref } from 'vue'
//#endregion

//#region EMITS AND PROPS
const emit = defineEmits(['update:modelValue'])
const props = defineProps({
  modelValue: { required: true },
  readonly: {
    type: Boolean,
    default: false
  },
  label: String,
  dense: {
    type: Boolean,
    default: false
  },
  options: {
    type: Array,
    default: () => []
  },
  onOptionSelect: { type: Function, },
  onInputUpdate: { type: Function, },
  defaultValue: {
    type: Object,
    default: null
  },
  autofocus: {
    type: Boolean,
    default: true
  },
})
//#endregion

//#region OPTIONS
const selectedOption = ref(null)
onMounted(() => {
  if (props.defaultValue) {
    const findCouple = props.options.find(o => o.value === props.defaultValue.value && o.label === props.defaultValue.label)
    if (findCouple) {
      selectedOption.value = findCouple
      return
    }
    const findByValue = props.options.find(o => o.value === props.defaultValue.value)
    if (findByValue) {
      selectedOption.value = findByValue
      return
    }
    selectedOption.value = props.defaultValue
    selectHandle(selectedOption.value)
  }
})
function selectHandle (selectedOption) {
  if (!selectedOption) { // Cleared
    if (props.onOptionSelect) props.onOptionSelect(null)
    else emit('update:modelValue', null)
  } else {
    if (props.onOptionSelect) props.onOptionSelect(selectedOption)
    else emit('update:modelValue', get(selectedOption, 'value', selectedOption))
  }
}
function inputHandle (inputValue) {
  if (props.onInputUpdate) props.onInputUpdate(inputValue)
}

const filteredOptions = ref([])
function filterFn (val, update, abort) {
  // Filter on at least 2 chars
  if (val.length < 2) {
    abort()
    return
  }
  update (() => {
    const needle = val.toLowerCase()
    filteredOptions.value = props.options.filter(o => o.label.toLowerCase().includes(needle))
  })
}
//#endregion
</script>
