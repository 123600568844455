<template>
  <div ref="pdfContainer" class="pdf-container"></div>
</template>

<script setup>
import { ref, onMounted, nextTick } from "vue";
import "pdfjs-dist/web/pdf_viewer.css";
const pdfjsLib = ref()

const props = defineProps({
  src: { type: String, required: true },
  searchTerms: { type: Array, default: () => [] }, // Array of keywords to be highlighted
});

const pdfContainer = ref(null);
const pdfPageHeight = ref(0);

const highlightBounds = (item, viewport, context) => {
  const bounds = [
    item.transform[4],
    item.transform[5] - item.height,
    item.transform[4] + item.width,
    item.transform[5],
  ];
  const viewportBounds = viewport.convertToViewportRectangle(bounds);
  const x = Math.min(viewportBounds[0], viewportBounds[2]);
  const y =
    Math.min(viewportBounds[1], viewportBounds[3]) -
    Math.abs(viewportBounds[1] - viewportBounds[3]);
  const width = Math.abs(viewportBounds[2] - viewportBounds[0]);
  const height = Math.abs(viewportBounds[3] - viewportBounds[1]);

  context.fillStyle = "rgba(255, 255, 0, 0.4)";
  context.fillRect(x, y, width, height);
};

const highlightText = (page, searchTerms, canvas, context, viewport) => {
  page.getTextContent({ disableCombineTextItems: true }).then((textContent) => {
    // Split textItems into individual characters and ignore spaces
    const textItems = textContent.items.flatMap((item) =>
      item.str.toLowerCase().replace(/[^a-zA-Z0-9]/g, "").split("").map((char) => ({
      ...item,
      str: char,
      }))
    ); 
     // Track already highlighted items
    const highlightedSet = new Set();
    searchTerms.forEach((searchTerm) => {
       // Split into individual characters and ignore spaces
      const searchChars = searchTerm.toLowerCase().replace(/\s+/g, "").split("");
      for (let i = 0; i < textItems.length; i++) {
        const match = searchChars.every((char, index) => {
          const item = textItems[i + index];
          return item && item.str.toLowerCase() === char;
        });

        if (match) {
          searchChars.forEach((_, index) => {
            const currentItem = textItems[i + index];
            const uniqueKey = `${currentItem.transform}-${currentItem.width}`;
            if (!highlightedSet.has(uniqueKey)) {
              highlightedSet.add(uniqueKey);
              highlightBounds(currentItem, viewport, context);
            }
          });
        }
      }
    });
  });
};

const renderPDF = async () => {
  if (!pdfContainer.value) return;
  pdfContainer.value.innerHTML = ""; // Clear the container
  const loadingTask = pdfjsLib.value.getDocument(props.src);
  const pdf = await loadingTask.promise;
  const scaleFactor = 2;
  const containerWidth = pdfContainer.value.clientWidth;
  // For each page of the PDF
  for (let pageNum = 1; pageNum <= pdf.numPages; pageNum++) {
    const page = await pdf.getPage(pageNum);
    const unscaledViewport = page.getViewport({ scale: 1 });
    const scale = (containerWidth / unscaledViewport.width) * scaleFactor;
    const viewport = page.getViewport({ scale });

    if (pageNum === 1) {
      pdfPageHeight.value = viewport.height;
      nextTick(() => {
        if (pdfContainer.value) {
          pdfContainer.value.style.maxHeight = `${
            pdfPageHeight.value / scale
          }px`;
        }
      });
    }

    const canvas = document.createElement("canvas");
    canvas.width = viewport.width;
    canvas.height = viewport.height;
    const context = canvas.getContext("2d");
    await page.render({ canvasContext: context, viewport }).promise;

    if (
      props.searchTerms &&
      props.searchTerms.length > 0 &&
      props.searchTerms[0] !== ""
    ) {
      highlightText(page, props.searchTerms, canvas, context, viewport);
    }

    const pageDiv = document.createElement("div");
    pageDiv.className = "pdf-page";
    pageDiv.appendChild(canvas);
    pdfContainer.value.appendChild(pageDiv);
  }
};

onMounted(async () => {
  pdfjsLib.value = await import("pdfjs-dist/legacy/build/pdf.mjs");
  pdfjsLib.value.GlobalWorkerOptions.workerSrc =
    "https://cdnjs.cloudflare.com/ajax/libs/pdf.js/5.0.375/pdf.worker.min.mjs";
  renderPDF();
});
</script>

<style>
.pdf-page canvas {
  max-width: 100%;
}

.pdf-page {
  margin-bottom: 1rem;
}
</style>
