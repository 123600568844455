import { words } from 'lodash'

// https://stackoverflow.com/a/36566052
// https://en.wikipedia.org/wiki/Levenshtein_distance
const similarity = (s1, s2) => {
  const longer = s1.length > s2.length ? s1 : s2
  const shorter = s1.length > s2.length ? s2 : s1
  const editDistance = words(shorter).reduce((acc, word) => acc + (longer.includes(word) ? 0 : 1), 0)
  return 1 - editDistance / longer.length
}

const stripTags = (val, allowedTags = '<p><a><br><br/><h1><h2><h3><h4><h5><h6>') => {
  if (val && typeof val === 'string') {
    const allowed = (((allowedTags || '') + '').toLowerCase().match(/<[a-z][a-z0-9]*>/g) || []).join('')
    const tags = /<\/?([a-z][a-z0-9]*)\b[^>]*>/gi
    return val.replace(tags, function ($0, $1) {
      return allowed.indexOf('<' + $1.toLowerCase() + '>') > -1 ? $0 : ''
    })
  } else return val
}

export {
  similarity,
  stripTags
}
