<template>
  <QEditor
    ref="editorRef"
    v-model.lazy="content"
    :min-height="minHeight"
    :max-height="maxHeight"
    class="full-width"
    :readonly="readonly"
    :toolbar="computedToolbar"
    :definitions="computedDefinitions"
    @paste="onPaste"
    @update:model-value="emit"
  >
    <template #token>
      <QBtnDropdown
        ref="tokenRef"
        dense
        no-caps
        no-wrap
        unelevated
        color="white"
        text-color="primary"
        label="Token"
        size="sm"
      >
        <QList dense>
          <QItem v-for="(token, index) of tokens" :key="index" tag="label" clickable @click="addToken(token)">
            <QItemSection side>
              <QIcon :name="token.icon" />
            </QItemSection>
            <QItemSection>{{ token.label }}</QItemSection>
          </QItem>
        </QList>
      </QBtnDropdown>
    </template>
  </QEditor>
</template>

<script>
import { stripTags } from 'hc-core/utils/formatting.js'

export default {
  props: {
    initialContent: {
      type: String,
      default: ''
    },
    maxChars: {
      type: Number,
      default: 3000
    },
    minHeight: {
      type: String,
      default: '5rem'
    },
    maxHeight: {
      type: String,
      default: '35rem'
    },
    propInsertToken: {
      type: String,
      default: null
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['setContent', 'customInput'],
  data () {
    return {
      content: '',
    }
  },
  computed: {
    tokens () {
      return [
        { label: this.$t('component.tools.editor.token_image'), icon: 'uil:image', content: '<img loading="lazy" alt="ALT" src="URL" />', replace: ['ALT', 'URL'] },
        { label: this.$t('component.tools.editor.token_article'), icon: 'uil:document-info', content: '[article=URL]', replace: ['URL'] },
        { label: this.$t('component.tools.editor.token_video'), icon: 'uil:youtube', content: '[youtube=URL]', replace: ['URL'] },
        { label: this.$t('component.tools.editor.token_spotify'), icon: 'uil:music', content: '[spotify=URL]', replace: ['URL'] },
      ]
    },
    computedToolbar () {
      if (process.env.HC_PLATFORM === 'MANAGER') {
        return [
          ['bold', 'italic', 'strike', 'underline', 'link'],
          [{
            label: 'Format',
            list: 'no-icons',
            options: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'code', 'removeFormat']
          }],
          ['unordered', 'ordered'],
          ['token'],
          ['undo', 'redo'],
          ['charsCounter']
        ]
      } else {
        return [
          ['bold', 'italic', 'strike', 'underline', 'link'],
          ['unordered', 'ordered'],
          ['undo', 'redo'],
          ['charsCounter']
        ]
      }
    },
    computedDefinitions () {
      return {
        charsCounter: {
          tip: this.$t('component.tools.editor.chars'),
          icon: 'uil:text-size',
          label: `${stripTags(this.content).length} / ${this.maxChars}`,
        },
      }
    }
  },
  watch: {
    initialContent: {
      handler: function (newInitial, oldInitial) { if (process.env.HC_PLATFORM !== 'MANAGER') this.content = newInitial }
    },
    propInsertToken: {
      handler: function (propInsertToken, oldPropInsertToken) {
        if (propInsertToken) {
          this.$refs.editorRef.runCmd('insertText', ` {${propInsertToken}} `)
          this.$refs.editorRef.caret.restore()
          this.$refs.editorRef.focus()
        }
      }
    },
  },
  mounted () {
    this.$nextTick(() => {
      setTimeout(() => { if (this.initialContent) this.content = this.initialContent }, 500)
    })
  },
  methods: {
    emit () { this.$emit(process.env.HC_PLATFORM === 'MANAGER' ? 'customInput' : 'setContent', this.content) },
    onPaste (evt) {
      // Let inputs do their thing, so we don't break pasting of links.
      if (evt.target.nodeName === 'INPUT') return
      let text, onPasteStripFormattingIEPaste
      evt.preventDefault()
      evt.stopPropagation()
      if (evt.originalEvent && evt.originalEvent.clipboardData.getData) {
        text = evt.originalEvent.clipboardData.getData('text/plain')
        this.$refs.editorRef.runCmd('insertText', text)
      } else if (evt.clipboardData && evt.clipboardData.getData) {
        text = evt.clipboardData.getData('text/plain')
        this.$refs.editorRef.runCmd('insertText', text)
      } else if (window.clipboardData && window.clipboardData.getData) {
        if (!onPasteStripFormattingIEPaste) {
          onPasteStripFormattingIEPaste = true
          this.$refs.editorRef.runCmd('ms-pasteTextOnly', text)
        }
        onPasteStripFormattingIEPaste = false
      }
    },
    async addToken (token) {
      if (this.$_.get(token, 'replace[0]', false)) {
        for (const replace of token.replace) {
          token.content = token.content.replace(replace, prompt(`Info à saisir ${replace}:`))
        }
      }
      this.$refs.tokenRef.hide()
      this.$refs.editorRef.caret.restore()
      this.$refs.editorRef.runCmd('insertHTML', `${token.content}`)
      this.$refs.editorRef.focus()
    }
  }
}
</script>
