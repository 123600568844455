<template>
  <div class="flex items-center">
    <template v-for="jobboardsLine of jobboards">
      <div v-for="jobboard of jobboardsLine" :key="jobboard" class="q-ma-xs q-pa-none bd-2 bd-grey-3 bg-white br-8 flex items-center justify-center" :style="`width:122px;height:66px;${ blurred ? 'opacity: 0.3;': '' }`">
        <img :src="cdnImg(`${basePath}${jobboard.img}`)" loading="lazy" style="max-width:115px;max-height:60px;">
        <ActionTooltip :str="jobboard.label" />
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    blurred: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      basePath: 'platform/jobboards/',
      jobboards: [
        [
          { label: 'Indeed', img: 'indeed_sponso.png' },
          { label: 'APEC', img: 'apec.png' },
          { label: 'Jobijoba', img: 'jobijoba.png' },
          { label: 'LinkedIn', img: 'linkedin.png' },
          { label: 'Monster', img: 'monster.png' },
          { label: 'FigaroEmploi', img: 'le_figaro_emploi.png' },
          { label: 'Glassdoor', img: 'glassdoor.png' }
        ],
        [
          { label: 'Adzuna', img: 'adzuna.png' },
          { label: 'PMEJob', img: 'pme_job.png' },
          { label: 'Google Jobs', img: 'google_jobs.png' },
          { label: 'Option Carrière', img: 'option_carriere.png' },
          { label: 'Jobrapido', img: 'jobrapido.png' },
          { label: 'L\'Étudiant', img: 'l_etudiant.png' },
          { label: 'AllJobs', img: 'alljobs.png' }
        ],
        [
          { label: 'Jooble', img: 'jooble.png' },
          { label: 'Jora', img: 'jora.png' },
          { label: 'Engagement Jeunes', img: 'engagement_jeunes.png' },
          { label: 'JobisJob', img: 'jobisjob.png' },
        ],
        [
          { label: 'France Travail', img: 'francetravail_soon.png' }
        ]
      ]
    }
  }
}
</script>
