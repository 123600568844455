<template>
  <QCard
    v-if="applicant"
    class="br-25 cursor-pointer bg-white bd-1 bd-grey-3"
    :class="{ 'bd-positive': isCVThequeUnlocked(applicant.id) }"
    :style="$route.name === 'offer' ? 'cursor:grab!important' : ''"
    @click.capture="emitEvent((lockCvtheque && !isCVThequeUnlocked(applicant.id)) ? { action: 'askUnlock' } : undefined)"
  >
    <QCardSection class="q-pb-sm">
      <div class="flex items-center justify-between no-wrap full-width">
        <div class="flex items-center no-wrap">
          <template v-if="$q.screen.gt.md">
            <QSkeleton
              v-if="cptLoading"
              type="circle"
              width="35px"
              height="35px"
              class="br-25"
            />
            <AppAvatar
              v-else
              size="35px"
              :entity="applicant.profileAsset ?? applicant"
            />
          </template>
          <div class="column justify-center items-start q-mx-sm">
            <QSkeleton
              v-if="cptLoading"
              type="text"
              height="2.3em"
              width="10em"
            />
            <div
              v-else
              class="text-weight-medium ellipsis"
              :style="isRestricted ? 'filter: blur(0.25rem)' : ''"
            >
              {{ $_.truncate(`${$_.get(applicant, 'firstname', '-')} ${$_.get(applicant, 'lastname', '-')}`) }}
              <QBadge
                v-if="$_.get(applicant, 'conversation.nbUnread', false)"
                color="negative"
                :label="applicant.conversation.nbUnread"
                align="middle"
              >
                <ActionTooltip :str="`${applicant.conversation.nbUnread} message(s) non lus`" />
              </QBadge>
            </div>
            <div
              v-if="jobrefLabel && $_.get(applicant, 'profileAsset.customAttributes.verifiedProfile', false)"
              class="flex items-center"
            >
              <div class="text-caption text-grey-5 q-mr-sm">
                {{ jobrefLabel }}
              </div>
              <QIcon
                name="uil:check-circle"
                color="positive"
                size="sm"
              >
                <ActionTooltip path="profile.profile_verified" />
              </QIcon>
            </div>
          </div>
        </div>
        <HCButton
          v-if="cvThequeUnlockTimestamp"
          color="positive"
          icon="uil:unlock"
          :tooltip="cvThequeUnlockTimestamp.since"
        />
        <slot v-else-if="!cvThequeUnlockTimestamp && lockCvtheque" name="checkbox-select" />
      </div>
    </QCardSection>
    <QSeparator class="q-my-xs q-px-md" />

    <QCardSection
      v-if="cptLoading"
      class="q-pt-none flex items-center q-gutter-sm"
    >
      <QSkeleton
        type="QChip"
        dense
        width="3em"
      />
      <QSkeleton
        type="QChip"
        dense
        width="5em"
      />
      <QSkeleton
        type="QChip"
        dense
        width="8em"
      />
    </QCardSection>
    <QCardSection
      v-else-if="chips.length"
      class="q-pt-none"
    >
      <QChip
        v-for="(chip, i) of chips"
        :key="i"
        :color="chip.color ?? 'white'"
        :text-color="chip.textColor ?? 'grey-7'"
        :icon="chip.icon"
        :label="chip.label"
        dense
        :clickable="typeof chip.fn === 'function'"
        class="cursor-pointer q-ml-none q-mr-sm q-pa-sm"
        @click.capture.stop="chip.fn ? chip.fn() : ''"
      >
        <ActionTooltip
          v-if="chip.tooltip"
          :str="chip.tooltip"
        />
      </QChip>
    </QCardSection>

    <!-- Notes -->
    <template v-if="$_.last(applicationsNotes)">
      <QSeparator class="q-my-xs q-px-md" />
      <QCardSection
        class="q-pt-none cursor-pointer"
        @click="emitEvent({
          action: isRestricted ? 'openRestricted' : 'applications',
          chip: isRestricted ? 'openRestricted' : 'applications',
        })"
      >
        <div class="flex items-center">
          <QIcon size="sm" color="grey-5" name="uil:notes" />
          <AppContent
            path="time.datetime"
            class="text-caption text-grey-5 q-ml-xs"
            :options="{ fmtd: $_.last(applicationsNotes).date }"
          />
          <AppContent
            v-if="$_.last(applicationsNotes).author"
            path="pages.blog.by"
            class="text-caption text-grey-5 q-ml-sm"
            :options="{ author: $_.last(applicationsNotes).author }"
          />
        </div>
        <div class="text-caption text-grey-5 ellipsis-2-lines">
          {{ $_.last(applicationsNotes).note }}
          <ActionTooltip :str="$_.last(applicationsNotes).note" />
        </div>
      </QCardSection>
    </template>

    <!-- Messages -->
    <template v-if="applicant.conversation && applicant.conversation.messages[0]">
      <QSeparator class="q-my-xs q-px-md" />
      <QCardSection
        class="q-pt-none cursor-pointer"
        @click="emitEvent({
          action: 'messages',
          chip: 'messages',
        })"
      >
        <div class="flex items-center">
          <QIcon size="sm" color="grey-5" name="uil:comments" />
          <AppContent
            path="time.datetime"
            class="text-caption text-grey-5 q-ml-xs"
            :options="{ fmtd: applicant.conversation.messages[0].createdDate }"
          />
          <AppContent
            path="pages.blog.by"
            class="text-caption text-grey-5 q-ml-sm"
            :options="{ author: applicant.conversation.messages[0].receiverId === applicant.id ? 'Vous' : applicant.firstname }"
          />
          <QChip
            v-if="$_.get(applicant, 'conversation.yourTurn', false)"
            label="À vous"
            size="md"
            dense
            class="q-ml-xs"
            color="green-4"
          />
        </div>
        <!-- {{ applicant.conversation.nbUnread }} -->
        <div class="text-caption text-grey-5 ellipsis-2-lines">
          <div v-html="applicant.conversation.messages[0].content" />
          <ActionTooltip>
            <div v-html="applicant.conversation.messages[0].content" />
          </ActionTooltip>
        </div>
      </QCardSection>
    </template>

    <QCardSection
      v-if="hcMenuActions.length"
      class="q-pt-none"
    >
      <HCMenu
        :actions="hcMenuActions"
        :element="applicant"
        force-expand
      />
    </QCardSection>
    <slot name="bottomActions" />
  </QCard>
</template>

<script>
import { $t } from 'hc-core/composables/intl'
import { fromNow } from 'hc-core/composables/time.js'
import { dlKeyFromS3 } from 'hc-core/composables/aws.js'
import { escapeUrl, pickFirstKey } from 'hc-core/composables/misc.js'
import ApplicantListMixins from 'hc-core/mixins/applicant-list.js'
import HCMenu from 'hc-core/components/common/hc-menu'
import { get, head, memoize, orderBy, truncate } from 'lodash'

// Memoize allows to store data and prevent re-calculation when not needed
const generateChips = memoize(({ applicant, isRestricted, emitEvent, applicationTime, applicationsNotesNb, isSubscribed, isFavorite, toggleApplicantOfList, cptCvProps }) => {
  const chips = []

  // DownloadResume
  // if (!!cptCvProps.cvPath && !cptCvProps.cvExpired) {
  //   chips.push({
  //     icon: 'uil:import',
  //     label: 'CV',
  //     tooltip: 'Télécharger le CV',
  //     fn: async (d) => {
  //       if (applicant.restricted || isRestricted) {
  //         emitEvent({
  //           action: 'openRestricted',
  //           chip: 'openRestricted',
  //         })
  //       } else {
  //         await dlKeyFromS3({
  //           type: 'pdf',
  //           key: !!cptCvProps.cvPath && !cptCvProps.cvExpired,
  //           dlLabel: `CV_${get(applicant, 'firstname', '')}_${get(applicant, 'lastname', '')}`
  //         })
  //       }
  //     }
  //   })
  // }

  // Last Activity
  const lastActivityValue = get(applicant, 'profileAsset.customAttributes.lastActivity', false)
  const lastActivity = lastActivityValue ? fromNow(lastActivityValue, true) : false
  if (lastActivity) {
    chips.push({
      icon: 'uil:clock',
      label: lastActivity,
      tooltip: 'Dernière activité',
      fn: (i) => {
        emitEvent({
          action: isRestricted ? 'openRestricted' : 'files',
          chip: isRestricted ? 'openRestricted' : 'files',
        })
      },
    })
  }

  // CV Update
  const cvUpdateValue = pickFirstKey(applicant, ['profileAsset.customAttributes.resumeUpdate', 'profileAsset.metadata._files.resumeUpdate', 'metadata._files.resumeUpdate'], false)
  const cvUpdate = cvUpdateValue ? fromNow(cvUpdateValue, true) : false
  if (cvUpdate) {
    chips.push({
      icon: 'uil:file-upload-alt',
      label: cvUpdate,
      tooltip: 'Mise à jour du CV',
      fn: (i) => {
        emitEvent({
          action: 'files',
          chip: 'files',
        })
      },
    })
  }

  // Location
  const location = pickFirstKey(applicant, ['profileAsset.locations[0]', 'metadata._private.locations[0]'], false)
  if (location) {
    chips.push({
      icon: 'uil:map-pin-alt',
      label: location.city,
      tooltip: 'Voir l\'adresse sur le CV du candidat',
      fn: async (d) => {
        emitEvent({
          action: 'openMapsOnLocation',
          location
        })
      }
    })
  }

  // Experience
  const experience = pickFirstKey(applicant, ['profileAsset.customAttributes.experienceNb', 'metadata._resume.experience'], false)
  if (experience) {
    chips.push({
      icon: 'uil:flask',
      label: `${experience} ans`,
      tooltip: 'Années d\'expérience',
      fn: async (d) => {
        emitEvent({
          action: isRestricted ? 'openRestricted' : 'files',
          chip: isRestricted ? 'openRestricted' : 'files',
        })
      }
    })
  }

  // Last - current job
  const lastJob = pickFirstKey(applicant, ['profileAsset.metadata._resume.experiences[0]', 'metadata._resume.experiences[0]'], false)
  if (lastJob) {
    chips.push({
      icon: 'uil:briefcase',
      label: truncate(lastJob.title),
      tooltip: 'Dernier poste (ou poste en cours)',
      fn: async (d) => {
        emitEvent({
          action: isRestricted ? 'openRestricted' : 'files',
          chip: isRestricted ? 'openRestricted' : 'files',
        })
      }
    })
  }

  // Education
  const education = pickFirstKey(applicant, ['profileAsset.metadata._resume.education', 'metadata._resume.education'], [])
  if (education.length) {
    chips.push({
      icon: 'uil:graduation-cap',
      label: `${truncate(get(education, '[0].title', 'Diplômes'))}${education.length > 1 ? ` (+${education.length - 1})` : ''}`,
      tooltip: education.map(e => e.title).join(', '),
      fn: async (d) => {
        emitEvent({
          action: isRestricted ? 'openRestricted' : 'files',
          chip: isRestricted ? 'openRestricted' : 'files',
        })
      }
    })
  }

  // LinkedIn
  const linkedin = pickFirstKey(applicant, ['profileAsset.metadata._resume.links.linkedin', 'metadata._private.links.linkedin'], false)
  if (linkedin) {
    chips.push({
      icon: 'uil:linkedin',
      label: 'LinkedIn',
      tooltip: 'Profil LinkedIn du candidat',
      color: 'linkedin',
      textColor: 'white',
      fn: async (d) => {
        if (applicant.restricted || isRestricted) {
          emitEvent({
            action: 'openRestricted',
            chip: 'openRestricted',
          })
        } else window.open(escapeUrl(linkedin), '_blank')
      }
    })
  }

  // NbApplications
  const itemApplications = get(applicant, 'applications', [])
  if (itemApplications.length) {
    chips.push({
      icon: 'uil:paperclip',
      label: itemApplications.length,
      tooltip: 'Candidatures',
      fn: async (d) => {
        emitEvent({
          action: isRestricted ? 'openRestricted' : 'applications',
          chip: isRestricted ? 'openRestricted' : 'applications',
        })
      }
    })
  }

  // FromNowTime
  const fromNowTime = applicationTime(head(orderBy(itemApplications, (a) => { return applicationTime(a) }, ['desc'])))
  if (fromNowTime) {
    chips.push({
      icon: 'uil:clock',
      label: fromNow(fromNowTime, true),
      tooltip: 'Denière candidature',
      fn: (i) => {
        emitEvent({
          action: 'applications',
          chip: 'applications',
        })
      },
    })
  }

  // NotesNb
  const notesNb = applicationsNotesNb
  if (notesNb) {
    chips.push({
      icon: 'uil:comment-alt-message',
      label: notesNb,
      color: 'blue-3',
      tooltip: `${notesNb} notes`,
      fn: (i) => {
        emitEvent({
          action: 'applications',
          chip: 'applications',
        })
      },
    })
  }

  // Favorite
  // if (isSubscribed()) {
  //   chips.push({
  //     icon: 'uil:star',
  //     label: 'Favori',
  //     color: isFavorite ? 'gold-4' : 'grey-2',
  //     tooltip: $t({ id: isFavorite ? 'applicantList.fav_remove' : 'applicantList.fav_add' }),
  //     fn: async (d) => { await toggleApplicantOfList(get(applicant, 'id', null)) }
  //   })
  // }
  return chips
}, ({ applicant, isFavorite }) => JSON.stringify({ applicant, isFavorite }))

const generateHcMenuActions = memoize(({ applicant, isRestricted, isVivier, applicationsNotesNb, emitEvent, cptCvProps }) => {
  if (isRestricted || !isVivier) return []
  const notesNb = applicationsNotesNb
  const actions = [
    {
      icon: 'uil:notes',
      color: notesNb > 0 ? 'positive' : 'grey',
      label: $t({ id: 'component.cards.applicant_card.nb_notes' }, { nb: notesNb }),
      fn: (i) => {
        emitEvent({
          action: 'applications',
          chip: 'applications',
        })
      },
    },
    {
      icon: 'uil:envelope-alt',
      color: 'grey',
      label: 'component.cards.applicant_card.show_messages',
      fn: (i) => {
        emitEvent({
          action: 'messages',
          chip: 'messages',
        })
      },
    },
    {
      icon: 'uil:import',
      color: cptCvProps.cvPath ? 'positive' : 'grey',
      label: 'Télécharger le CV',
      if: !!cptCvProps.cvPath && !cptCvProps.cvExpired,
      fn: async (i) => {
        await dlKeyFromS3({
          type: 'pdf',
          key: cptCvProps.cvPath,
          dlLabel: `CV_${get(applicant, 'firstname', '')}_${get(applicant, 'lastname', '')}`
        })
      }
    }
  ]
  if (actions.filter(a => a.if).length) return actions
  else return []
}, ({ applicant }) => JSON.stringify({ applicant }))

export default {
  components: { HCMenu },
  mixins: [ApplicantListMixins],
  props: {
    applicant: {
      type: Object,
      default: null
    },
    // If in context of CVTheque, base restricted status on presence in CVThqueList
    lockCvtheque: {
      type: Boolean,
      default: false
    },
    // If in context of CVTheque, show loading state when unlock is in progress
    propLoading: {
      type: Boolean,
      default: false
    },
  },
  emits: ['chipClick'],
  data () {
    return {
      loading: false,
      // Stelace user, with added properties : restricted<Boolean>, applications<Array>, profileAsset<Asset of Profile type>
      // applicant: null
    }
  },
  computed: {
    isVivier () { return this.$route.name === 'applicants' },
    cptLoading () { return this.propLoading || this.loading },

    // Snippet : check restricted status for one applicant
    // Restricted is not paid client OR (inCVTheque AND not Premium)
    isRestricted () { return this.$_.get(this.applicant, 'applications', []).map(app => app.status).includes('restricted') || (this.lockCvtheque && (!this.isPremium() && !this.isCVThequeUnlocked(this.applicant.id))) },

    cptCvProps () {
      const cvPath = pickFirstKey(this.applicant, ['profileAsset.metadata._files.resume', 'metadata._files.resume', 'resume.metadata._files.resume', 'metadata._resume.file', 'metadata._resume.indeed.file'], false) // First two are legits, others are relinquats
      const cvUpdate = pickFirstKey(this.applicant, ['profileAsset.customAttributes.resumeUpdate', 'profileAsset.metadata._files.resumeUpdate', 'metadata._files.resumeUpdate'], false)
      const expiredDate = new Date()
      expiredDate.setFullYear(expiredDate.getFullYear() - 2)
      expiredDate.setDate(expiredDate.getDate() - 1 )
      const cvExpired = !cvPath || pickFirstKey(this.applicant, ['profileAsset.metadata._files.resumeExpired', 'metadata._files.resumeExpired'], false) || (cvUpdate && new Date(cvUpdate) <= expiredDate)
      return { cvPath, cvUpdate, expiredDate, cvExpired }
    },

    cvThequeUnlockTimestamp () {
      if (this.isCVThequeUnlocked(this.applicant.id)) {
        const cvthequeStructItem = this.$_.get(this.cvthequeList, 'data.listStructure[0].applicantsIds', []).find(item => (this.$_.isString(item) ? item === this.applicant.id : item.id === this.applicant.id))
        return {
          unlocked: true,
          since: this.$_.get(cvthequeStructItem, 'timestamp', false) ? `Date de déverrouillage : ${this.$t({ id: 'time.date' }, { fmtd: cvthequeStructItem.timestamp })}` : 'Candidat déverrouillé'
        }
      } else return false
    },

    jobrefLabel () {
      let val = this.$_.get(this.applicant, 'profileAsset.customAttributes.jobReferential', null)
      if (val) val = this.$_.get(this.getCustomAttributeValues('jobReferential').find(jr => jr.value === val), 'label', null)
      else val = this.$_.get(this.applicant, 'metadata._resume.preferredJob', null)
      return val
    },

    // Snippet : grab and order all applications notes
    applicationsNotes () {
      return this.$_.orderBy(
        this.$_.flattenDeep(
          this.$_.get(this.applicant, 'applications', []).map(a => this.$_.get(a, 'metadata._company.notes', []))
        ), ['date'], ['asc'])
    },

    chips () {
      return generateChips({
        applicant: this.applicant,
        isRestricted: this.isRestricted,
        emitEvent: this.emitEvent,
        applicationTime: this.applicationTime,
        applicationsNotesNb: this.applicationsNotes.length,
        isSubscribed: this.isSubscribed,
        isFavorite: this.isFavorite(this.applicant.id),
        toggleApplicantOfList: this.toggleApplicantOfList,
        cptCvProps: this.cptCvProps,
      })
    },

    // Actions for HCMenu
    hcMenuActions () {
      return generateHcMenuActions({
        applicant: this.applicant,
        isRestricted: this.isRestricted,
        isVivier: this.isVivier,
        applicationsNotesNb: this.applicationsNotes.length,
        emitEvent: this.emitEvent,
        cptCvProps: this.cptCvProps,
      })
    },
  },
  methods: {
    fromNow,

    // Snippet : lastTime creation for application
    applicationTime (a) { return this.pickFirstKey(a, ['platformData.unrestrictedDate', 'platformData._v1.created', 'createdDate']) },

    // ##### Below are computedItems snippets #####
    emitEvent (evt) {
      this.$emit('chipClick', {
        // Default, doubled since both used by kanbanv2 and applicantsDisplay, but not same handling
        item: this.$_.set(this.applicant, 'isRestricted', this.isRestricted),
        value: this.$_.set(this.applicant, 'isRestricted', this.isRestricted),
        chip: 'files',
        action: 'files',
        // Then override
        ...evt
      })
    },
  }
}
</script>
