<template>
  <QUploader
    v-if="uploadConfig"
    ref="FileUploader"
    flat
    bordered
    max-files="1"
    no-thumbnails
    :factory="upload"
    text-color="secondary"
    class="full-width br-25"
    max-file-size="2500000"
    :accept="$_.get(uploadConfig, 'accept', 'application/pdf')"
    :label="$t({ id: uploadConfig.label ?? 'prompt.upload_attachments_button' })"
    :auto-upload="$_.get(uploadConfig, 'autoUpload', true)"
    @rejected="rejectUpload"
  >
    <template #header="scope">
      <div class="row no-wrap items-center q-pa-sm q-gutter-xs">
        <QBtn
          v-if="scope.uploadedFiles.length > 0"
          icon="uil:check"
          round
          dense
          flat
          @click="scope.removeUploadedFiles"
        >
          <ActionTooltip path="component.dialogs.apply_dialog.file_rm_up" />
        </QBtn>
        <QSpinnerPuff
          v-if="scope.isUploading"
          class="QUploader__spinner"
        />
        <div class="col">
          <div class="text-weight-bold QUploader__title">
            {{ $t({ id: uploadConfig.label ?? 'prompt.upload_attachments_button' }) }}
          </div>
          <div
            v-if="uploadConfig.subtitle"
            class="QUploader__subtitle text-caption text-grey-5 text-italic"
          >
            {{ $t({ id: uploadConfig.subtitle }) }}
          </div>
        </div>
        <QUploaderAddTrigger v-if="scope.canAddFiles" />
        <QBtn
          v-if="scope.canAddFiles"
          type="a"
          icon="uil:plus-circle"
          round
          dense
          flat
        >
          <ActionTooltip path="component.dialogs.apply_dialog.file_choose" />
        </QBtn>
        <QBtn
          v-if="scope.queuedFiles.length > 0"
          icon="uil:multiply"
          round
          dense
          flat
          @click="scope.removeQueuedFiles"
        >
          <ActionTooltip path="component.dialogs.apply_dialog.file_delete" />
        </QBtn>

        <QBtn
          v-if="scope.isUploading"
          icon="uil:times"
          round
          dense
          flat
          @click="scope.abort"
        >
          <ActionTooltip path="component.dialogs.apply_dialog.file_abort" />
        </QBtn>
      </div>
    </template>

    <template #list="scope">
      <AppContent
        v-if="uploadConfig.hint"
        :path="uploadConfig.hint"
        class="text-grey-5 text-italic text-body2 q-mb-md"
      />
      <QUploaderAddTrigger />
      <QList separator>
        <QItem
          v-for="file in scope.files"
          :key="file.name"
        >
          <QItemSection>
            <QItemLabel class="flex justify-between full-width ellipsis">
              <div class="text-weight-medium">
                {{ file.name }}
              </div>
              <div class="text-caption text-grey-5">
                Statut: {{ file.__status }} ({{ file.__sizeLabel }} / {{ file.__progressLabel }})
              </div>
            </QItemLabel>
          </QItemSection>
        </QItem>
      </QList>
    </template>
  </QUploader>
</template>

<script>
import { keyAfterUploadS3 } from 'hc-core/composables/aws.js'

export default {
  props: {
    uploadConfig: {
      type: Object,
      required: true
    }
  },
  emits: ['hide', 'uploaded'],
  methods: {
    async upload (file) {
      const fileKey = await keyAfterUploadS3({
        file: file[0],
        entity: this.$_.get(this.uploadConfig, 'entity', undefined),
        options: this.uploadConfig
      })
      if (fileKey) {
        const uploaded = {}
        this.$_.set(uploaded, `attrs.${this.uploadConfig.field}`, fileKey)
        this.$_.set(uploaded, 'id', this.uploadConfig.id)
        this.$emit('uploaded', uploaded)
        this.$refs.FileUploader.removeUploadedFiles()
        this.$refs.FileUploader.reset()
        return uploaded
      } else this.notifyError()
      this.$emit('hide')
      return null
    },
    rejectUpload () { this.notifyError('error.choose_pdf') },
  }
}
</script>
